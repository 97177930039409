var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('header', [_c('h3', {
    staticClass: "text-center mt-4"
  }, [_vm._v(" Energy Monitor "), _c('small', [_vm._v("(" + _vm._s(_vm.monitorStatus) + ")")])]), _c('h4', {
    staticClass: "text-center mb-4"
  }, [_c('small', [_vm._v("Now:")]), _vm._v(" " + _vm._s(_vm.countAllNow) + " "), _c('small', [_vm._v("kw/h")]), _c('small', {
    staticClass: "ml-3"
  }, [_vm._v("Today: " + _vm._s(_vm.countAllToday))])])]), _c('div', {
    staticClass: "row mb-5"
  }, _vm._l(_vm.getData, function (item, index) {
    return _c('AppBox', {
      key: index,
      staticClass: "col-xs-12 col-md-6 col-lg-4",
      attrs: {
        "header": item.name
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("Moc:")]), _c('strong', [_vm._v(" " + _vm._s(item.power) + " ")])]), _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("Razem:")]), _c('strong', [_vm._v(" " + _vm._s((item.fe - item.re).toFixed(2)) + " kw ")])]), _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("Napięcie:")]), _c('strong', [_vm._v(" " + _vm._s(item.voltage) + " v ")])]), item.invVoltage ? _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("INV Napięcie:")]), _c('strong', [_vm._v(" " + _vm._s(item.invVoltage) + " v ")])]) : _vm._e(), item.invState !== undefined ? _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("INV Tryb:")]), _c('strong', [item.invState === 0 ? [_vm._v("Standby")] : _vm._e(), item.invState === 2 ? [_vm._v("ON-grid")] : _vm._e(), item.invState === 3 ? [_vm._v("OFF-grid")] : _vm._e()], 2)]) : _vm._e(), item.invPv ? _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("INV PV teraz:")]), _c('strong', [_vm._v(" " + _vm._s(item.invPv) + " kw/h ")])]) : _vm._e(), item.invConsNow ? _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("INV Zużycie teraz:")]), _c('strong', [_vm._v(" " + _vm._s(item.invConsNow) + " kw/h")])]) : _vm._e(), item.invConsumption ? _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("INV Zużycie dzisiaj:")]), _c('strong', [_vm._v(" " + _vm._s(item.invConsumption) + " kw ")])]) : _vm._e(), item.invGeneration ? _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("INV Produkcja dzisiaj:")]), _c('strong', [_vm._v(" " + _vm._s(item.invGeneration) + " kw ")])]) : _vm._e(), item.invBatteryProc ? _c('div', {
      staticClass: "d-flex justify-content-between mx-3 mb-1"
    }, [_c('div', [_vm._v("INV Bateria:")]), _c('strong', [_vm._v(" " + _vm._s(item.invBatteryProc) + "%(" + _vm._s(item.invBatteryVoltage) + ") " + _vm._s(item.invBatteryCurrent) + "A/" + _vm._s(item.invBatteryPower) + " kw ")])]) : _vm._e()]);
  }), 1), _c('AppBox', {
    attrs: {
      "data-slideout-ignore": ""
    }
  }, [_c('AppYearMonthSelect', {
    model: {
      value: _vm.statsDate,
      callback: function ($$v) {
        _vm.statsDate = $$v;
      },
      expression: "statsDate"
    }
  }), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-bordered"
  }, [_c('thead', [_c('tr', [_c('th'), _c('th', [_vm._v("Produkcja (L1/L2/L3)")]), _c('th', [_vm._v("Zużycie (L1/L2/L3)")]), _c('th', [_vm._v("Suma")])])]), _c('tbody', [_vm._l(_vm.monitorStats, function (item) {
    return _c('tr', {
      key: JSON.stringify(item._id)
    }, [_c('td', [_vm._v(" " + _vm._s(item._id.day) + " / " + _vm._s(item._id.month) + " / " + _vm._s(item._id.year) + " ")]), _c('td', [_vm._v(" " + _vm._s(item.sum_reverse) + " (" + _vm._s(`${item.reverse_l1_re}/${item.reverse_l2_re}/${item.reverse_l3_re}`) + ") ")]), _c('td', [_vm._v(" " + _vm._s(item.sum_consumption) + " (" + _vm._s(`${item.consumption_l1_fe}/${item.consumption_l2_fe}/${item.consumption_l3_fe}`) + ") ")]), _c('td', [_c('strong', [_vm._v(_vm._s(item.sum_energy))])])]);
  }), _c('tr', [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }), _c('td', [_vm._v(_vm._s(_vm.monitorEnergySum.toFixed(2)))])])], 2)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }