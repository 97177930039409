<template>
  <div class="container">
    <header>
      <h3 class="text-center mt-4">
        Energy Monitor <small>({{ monitorStatus }})</small>
      </h3>
      <h4 class="text-center mb-4">
        <small>Now:</small> {{ countAllNow }} <small>kw/h</small>
        <small class="ml-3">Today: {{ countAllToday }}</small>
      </h4>
    </header>

    <!-- <pre>
      {{ this.common }}
    </pre> -->

    <!-- <pre>
      {{ voltageData }}
    </pre> -->

    <div class="row mb-5">
      <AppBox
        v-for="(item, index) in getData"
        :key="index"
        :header="item.name"
        class="col-xs-12 col-md-6 col-lg-4"
      >
        <div class="d-flex justify-content-between mx-3 mb-1">
          <div>Moc:</div>
          <strong>
            {{ item.power }}
          </strong>
        </div>
        <div class="d-flex justify-content-between mx-3 mb-1">
          <div>Razem:</div>
          <strong> {{ (item.fe - item.re).toFixed(2) }} kw </strong>
        </div>
        <div class="d-flex justify-content-between mx-3 mb-1">
          <div>Napięcie:</div>
          <strong> {{ item.voltage }} v </strong>
        </div>

        <div
          v-if="item.invVoltage"
          class="d-flex justify-content-between mx-3 mb-1"
        >
          <div>INV Napięcie:</div>
          <strong> {{ item.invVoltage }} v </strong>
        </div>

        <div
          v-if="item.invState !== undefined"
          class="d-flex justify-content-between mx-3 mb-1"
        >
          <div>INV Tryb:</div>
          <strong>
            <template v-if="item.invState === 0">Standby</template>
            <template v-if="item.invState === 2">ON-grid</template>
            <template v-if="item.invState === 3">OFF-grid</template>
          </strong>
        </div>

        <div v-if="item.invPv" class="d-flex justify-content-between mx-3 mb-1">
          <div>INV PV teraz:</div>
          <strong> {{ item.invPv }} kw/h </strong>
        </div>

        <div
          v-if="item.invConsNow"
          class="d-flex justify-content-between mx-3 mb-1"
        >
          <div>INV Zużycie teraz:</div>
          <strong> {{ item.invConsNow }} kw/h</strong>
        </div>

        <div
          v-if="item.invConsumption"
          class="d-flex justify-content-between mx-3 mb-1"
        >
          <div>INV Zużycie dzisiaj:</div>
          <strong> {{ item.invConsumption }} kw </strong>
        </div>

        <div
          v-if="item.invGeneration"
          class="d-flex justify-content-between mx-3 mb-1"
        >
          <div>INV Produkcja dzisiaj:</div>
          <strong> {{ item.invGeneration }} kw </strong>
        </div>

        <div
          v-if="item.invBatteryProc"
          class="d-flex justify-content-between mx-3 mb-1"
        >
          <div>INV Bateria:</div>
          <strong>
            {{ item.invBatteryProc }}%({{ item.invBatteryVoltage }})
            {{ item.invBatteryCurrent }}A/{{ item.invBatteryPower }} kw
          </strong>
        </div>
      </AppBox>
    </div>

    <AppBox data-slideout-ignore>
      <AppYearMonthSelect v-model="statsDate" />

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th></th>
              <th>Produkcja (L1/L2/L3)</th>
              <th>Zużycie (L1/L2/L3)</th>
              <th>Suma</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in monitorStats" :key="JSON.stringify(item._id)">
              <td>
                {{ item._id.day }} / {{ item._id.month }} /
                {{ item._id.year }}
              </td>
              <td>
                {{ item.sum_reverse }}
                ({{
                  `${item.reverse_l1_re}/${item.reverse_l2_re}/${item.reverse_l3_re}`
                }})
              </td>
              <td>
                {{ item.sum_consumption }}
                ({{
                  `${item.consumption_l1_fe}/${item.consumption_l2_fe}/${item.consumption_l3_fe}`
                }})
              </td>
              <td>
                <strong>{{ item.sum_energy }}</strong>
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td>{{ monitorEnergySum.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </AppBox>

    <!-- <pre>
      {{ monitorStats }}
    </pre> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";

import Api from "@/components/mixins/api";
import StatBtn from "../components/helpers/stat-btn.vue";
import DateSettings from "@/components/helpers/date-settings";

export default {
  name: "Inverter",

  components: {
    StatBtn,
    DateSettings,
    AppYearMonthSelect: () => import("@/components/common/AppYearMonthSelect"),
  },

  mixins: [Api],

  data() {
    return {
      monitorStats: [],
      monitorEnergySum: 0,
      monitorLoading: false,
      monitorQuery: {},
      refreshInterval: null,
      statsDate: null,
    };
  },

  computed: {
    ...mapState(["driversData"]),
    ...mapState(["common"]),

    inverterData() {
      return this.$store.state.inverter;
    },

    piec() {
      if ("piec" in this.driversData) {
        return this.driversData.piec;
      } else {
        return false;
      }
    },

    countAllNow() {
      const { l1_power, l2_power, l3_power } = this.common || {};
      const power = (
        parseFloat(l1_power) +
        parseFloat(l2_power) +
        parseFloat(l3_power)
      ).toFixed(2);
      return (power / 1000).toFixed(2);
    },

    countAllToday() {
      const { garaz = {}, dom = {}, dom2 = {} } = this.inverterData || {};
      return (garaz.today + dom.today + dom2.today).toFixed(2);
    },

    monitorStatus() {
      const { monitorStatus } = this.common || {};
      return monitorStatus;
    },

    getData() {
      const {
        l1_power = 0,
        l1_voltage,
        l1_forward_energy,
        l1_reverse_energy,
        l2_power = 0,
        l2_voltage,
        l2_forward_energy,
        l2_reverse_energy,
        l3_power,
        l3_voltage,
        l3_forward_energy,
        l3_reverse_energy,
        sofar_dom: sofarDom,
        sofar_garaz: sofarGaraz,
      } = this.common || {};

      // console.log(sofarDom)

      const { dom2 = {}, garaz = {} } = this.inverterData || {};

      return [
        {
          name: "Garaz - L1",
          power: parseFloat(l1_power).toFixed(2),
          voltage: l1_voltage,
          fe: parseInt(l1_forward_energy),
          re: parseInt(l1_reverse_energy),
          invGeneration: garaz.today && garaz.today.toFixed(2),
          invPv: garaz.now && (garaz.now / 1000).toFixed(2),
        },

        {
          name: "Dom 2 - L2",
          power: parseFloat(l2_power).toFixed(2),
          voltage: l2_voltage,
          fe: parseInt(l2_forward_energy),
          re: parseInt(l2_reverse_energy),
          invGeneration: dom2.today && dom2.today.toFixed(2),
          invPv: dom2.now && (dom2.now / 1000).toFixed(2),
        },

        {
          name: "Dom 1 - L3",
          power: parseFloat(l3_power).toFixed(2),
          voltage: l3_voltage,
          fe: parseInt(l3_forward_energy),
          re: parseInt(l3_reverse_energy),
          ...this.getHybridData(sofarDom),
        },
      ];
    },
  },

  watch: {
    monitorQuery: {
      handler() {
        this.getEnergyMonitorData();
      },
      deep: true,
      immediate: true,
    },

    statsDate(value) {
      this.monitorQuery = {
        dateFrom: dayjs(value).startOf("month").format(),
        dateTo: dayjs(value).endOf("month").format(),
      };
      console.log(this.monitorQuery)
    },
  },

  mounted() {
    this.init();
  },

  beforeDestroy() {
    clearTimeout(this.refreshInterval);
  },

  methods: {
    init() {
      this.monitorQuery = {
        dateFrom: dayjs().startOf("month").format(),
        dateTo: dayjs().format(),
      };

      this.refreshInterval = setInterval(this.getEnergyMonitorData, 1000 * 15);
    },

    getHybridData(data) {
      if (data) {
        // console.log(data);
        return {
          invPv: (data.solarPV / 100).toFixed(2),
          invVoltage: (parseInt(data.grid_voltage) / 10).toFixed(1),
          invConsumption: (data.today_consumption / 100).toFixed(2),
          invConsNow: (data.consumption / 100).toFixed(2),
          invGeneration: (data.today_generation / 100).toFixed(2),
          invState: data.running_state,
          invBatteryProc: data.batterySOC,
          invBatteryVoltage: (parseInt(data.battery_voltage) / 10).toFixed(1),
          invBatteryCurrent: (data.battery_current / 100).toFixed(2),
          invBatteryPower: (data.battery_power / 100).toFixed(2) || 0
        };
      } else {
        return {};
      }
    },

    async getEnergyMonitorData() {
      try {
        const data = await this.apiGet({
          type: "db",
          method: "getEnergyMonitorData",
          query: this.monitorQuery,
        });
        this.monitorStats = data;
        this.monitorEnergySum = data.reduce((acc, item) => {
          return acc + parseFloat(item.sum_energy);
        }, 0);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
